import styled, { css } from 'styled-components';
import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Stack } from '@tymate/margaret';
import { MdToday, MdClose, MdArrowForward } from 'react-icons/md';
import { FormLabel } from 'ui/forms';
import { DatesPickerStyles } from './DatePicker';
import 'react-date-picker/dist/DatePicker.css';

const Icon = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.secondary};
  font-size: 18px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.disabled};
    `}

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme }) => theme.disabled};
      font-size: 16px;
    `}

  ${({ variant }) =>
    variant === 'rangeDivider' &&
    css`
      color: ${({ theme }) => theme.textLight};
      padding: 0 ${({ theme }) => theme.spacing()};
    `}
`;

const Wrapper = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: ${({ theme }) => theme.borderRadius.large};

  input {
    color: ${({ theme }) => theme.textLight};

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${({ theme }) => theme.disabled};
      `}
  }
`;

const DateRangePicker = ({
  disabled,
  label,
  onChange,
  value,
  minDate,
  maxDate,
  isClearable = true,
  wrapperStyle,
  ...props
}) => (
  <Stack
    direction="column"
    gutterSize={0.5}
    alignX="stretch"
    style={wrapperStyle}
  >
    {Boolean(label) && <FormLabel>{label}</FormLabel>}
    <Wrapper disabled={disabled} style={wrapperStyle}>
      <ReactDateRangePicker
        value={value}
        onChange={onChange}
        locale="fr-FR"
        maxDate={maxDate}
        minDate={minDate}
        format="dd/MM/yyyy"
        disabled={disabled}
        clearIcon={
          isClearable && (
            <Icon variant="light">
              <MdClose />
            </Icon>
          )
        }
        calendarIcon={
          <Icon disabled={disabled}>
            <MdToday />
          </Icon>
        }
        rangeDivider={
          <Icon variant="rangeDivider">
            <MdArrowForward />
          </Icon>
        }
        {...props}
      />
      <DatesPickerStyles />
    </Wrapper>
  </Stack>
);

export default DateRangePicker;
