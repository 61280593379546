import { gql } from '@apollo/client';

export const GET_HOTEL_KEEPER_ADMIN_BOOKING = gql`
  query hotelKeeperAdminBooking($secret: String!) {
    hotelKeeperAdminBooking(secret: $secret) {
      id
      hotelKeeperMessage
      hotelKeeperMessageSendAt
      customerAdditionalAddress
      customerAddress
      customerCity
      customerCompany
      customerEmail
      customerFirstname
      customerLastname
      customerPhoneNumber
      customerZipCode
      canceledAt
      kind
      currency
      dmcTotalPriceWithoutPromo
      totalPrice
      note
      operatorTourName
      networkName
      numberOfNights
      hotel {
        id
        stars
        address
        cityName
        regionName
        countryName
        displayName
        primaryAttachment {
          imageUrl(size: MEDIUM)
        }
      }
      roomCompositions {
        id
        dmcPrice
        dmcPricePerDay
        dmcPriceWithoutPromo
        bedCompositions {
          label
          quantity
        }
        hotelRoom {
          displayName
        }
        travelers {
          id
          kind
          gender
          firstName
          lastName
          birthdate
          passportNumber
          expirationDate
          nationality
        }
        promotions {
          kind
          promotionKind
          value
        }
      }
      state
      startAt
      endAt
    }
  }
`;

export const UPDATE_HOTEL_KEEPER_ADMIN_BOOKING = gql`
  mutation updateHotelKeeperAdminBooking(
    $input: UpdateHotelKeeperAdminBookingInput!
  ) {
    updateHotelKeeperAdminBooking(input: $input) {
      errors {
        message
      }
    }
  }
`;
