import { Stack, ButtonReset } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { IoPeopleOutline } from 'react-icons/io5';
import styled, { useTheme } from 'styled-components';
import * as Yup from 'yup';
import { Button } from 'components';
import { TextAreaField } from 'components/Fields';
import { Text, SectionCard } from 'ui';
import { IcHotel } from 'components/icons';
import HotelRoomPromotion from 'components/BookingTunnel/HotelRoomPromotion';
import { RoomTag, CrossedPrice } from 'components/BookingTunnel/UIComponents';
import { formatCurrency } from 'utils';

const CardTitle = styled(Text).attrs({
  type: 'bodyLarge',
  fontWeight: 700,
  color: 'text',
})`
  &:first-letter {
    text-transform: uppercase;
  }
`;

const OtherProposalAdminBookingForm = ({ onSubmit, onClose, booking }) => {
  const theme = useTheme();
  const { t } = useTranslation('bookings');

  const initialValues = {
    hotelKeeperMessage: '',
    roomCompositions: booking?.roomCompositions?.map(({ id }) => ({
      roomCompositionId: id,
      otherProposal: false,
    })),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        hotelKeeperMessage: Yup.string().required(t('errors:required')),
      })}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Stack size="full" direction="column" paddingTop={1} gutterSize={2}>
            <Stack direction="column" size="full" gutterSize={1}>
              {booking?.roomCompositions.map(roomComposition => {
                const isSelected = values?.roomCompositions.some(
                  ({ roomCompositionId, otherProposal }) =>
                    roomCompositionId === roomComposition.id && otherProposal,
                );
                const bedRooms = roomComposition?.bedCompositions
                  ?.map(bedCompo => `${bedCompo.quantity} ${bedCompo.label}`)
                  ?.join(', ');

                return (
                  <SectionCard
                    key={roomComposition.id}
                    as={ButtonReset}
                    onClick={() => {
                      const draftRoomCompositions =
                        values?.roomCompositions.map(
                          ({ roomCompositionId, otherProposal }) => {
                            if (roomCompositionId === roomComposition.id) {
                              return {
                                roomCompositionId,
                                otherProposal: !otherProposal,
                              };
                            }
                            return {
                              roomCompositionId,
                              otherProposal,
                            };
                          },
                        );

                      setFieldValue('roomCompositions', draftRoomCompositions);
                    }}
                    isSelected={isSelected}
                  >
                    <Stack gutterSize={0.75} size="full">
                      <Stack marginTop={0.188}>
                        {isSelected ? (
                          <MdRadioButtonChecked
                            color={theme.primary}
                            size={24}
                          />
                        ) : (
                          <MdRadioButtonUnchecked size={24} />
                        )}
                      </Stack>
                      <Stack direction="column" size="full" gutterSize={1}>
                        <Stack
                          size="full"
                          gutterSize={1}
                          alignX="space-between"
                          alignY="flex-end"
                        >
                          <Stack direction="column" gutterSize={0.375}>
                            <div>
                              <CardTitle>
                                {roomComposition?.hotelRoom?.displayName}
                              </CardTitle>
                            </div>
                            <Stack gutterSize={0.5} alignY="center">
                              <RoomTag
                                value={t('nGuests', {
                                  count: roomComposition?.travelers?.length,
                                })}
                                icon={IoPeopleOutline}
                              />
                              <RoomTag value={bedRooms} icon={IcHotel} />
                            </Stack>
                            {roomComposition?.promotions?.length > 0 && (
                              <Stack
                                style={{
                                  flexWrap: 'wrap',
                                  gap: '0.5rem',
                                }}
                              >
                                {roomComposition?.promotions?.map(
                                  (promotion, index) => (
                                    <HotelRoomPromotion
                                      key={index}
                                      name={promotion?.value}
                                      kind={promotion?.promotionKind}
                                    />
                                  ),
                                )}
                              </Stack>
                            )}
                          </Stack>

                          <Stack direction="column" alignX="end">
                            <Stack direction="row" gutterSize={1}>
                              {roomComposition?.dmcPriceWithoutPromo !==
                                roomComposition?.dmcPrice && (
                                <CrossedPrice type="bodyLarge">
                                  {formatCurrency({
                                    amount:
                                      roomComposition?.dmcPriceWithoutPromo,
                                    currency: booking?.currency,
                                  })}
                                </CrossedPrice>
                              )}
                              <Text
                                type="bodyLarge"
                                color="text"
                                fontWeight={700}
                              >
                                {formatCurrency({
                                  amount: roomComposition?.dmcPrice,
                                  currency: booking?.currency,
                                })}
                              </Text>
                            </Stack>
                            <Stack direction="row">
                              <Text type="bodySmall" color="gray">
                                {t('pricePerNight', {
                                  price: formatCurrency({
                                    amount: roomComposition.dmcPricePerDay,
                                    currency: booking?.currency,
                                  }),
                                })}
                              </Text>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </SectionCard>
                );
              })}
            </Stack>
            <TextAreaField
              name="hotelKeeperMessage"
              label={t('hotelKeeper:newProposal')}
              labelRight={t('misc:required')}
            />
            <Stack size="full" alignX="flex-end" gutterSize={1}>
              <Button type="button" variant="simple" onClick={onClose}>
                {t('misc:cancel')}
              </Button>
              <Button
                variant="primary"
                isLoading={isSubmitting}
                type="submit"
                disabled={
                  !values?.roomCompositions?.some(
                    ({ otherProposal }) => otherProposal === true,
                  )
                }
              >
                {t('hotelKeeper:submit.otherProposal')}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default OtherProposalAdminBookingForm;
