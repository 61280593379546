import { useRef } from 'react';
import { Text } from 'ui';
import {
  Dropdown,
  PopoverContainer,
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
  ButtonReset,
  Stack,
} from '@tymate/margaret';
import { isEqual, includes, find } from 'lodash';
import { useDeepCompareEffect } from 'react-use';
import styled, { css } from 'styled-components';
import { IcArrowDown, IcRemoveCircle } from 'components/icons';
import { FormLabel, FormField } from 'ui/forms';

export const Trigger = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  max-width: 100%;
  min-width: 176px;
  min-height: 36px;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  transition: border-color 100ms ease;
  z-index: 1;

  ${({ disabled, theme }) =>
    disabled &&
    `
      cursor: not-allowed;
      background-color: ${theme.disabled};
      color: #6d6d6d;
  `}

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.disabled};
    color: #6d6d6d;
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &:not([disabled]),
      &:not([disabled]):hover,
      &:not([disabled]):active {
        border: 1px solid ${theme.danger};
      }
    `}
`;

const SelectedOptions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  margin: ${({ theme }) => theme.spacing(-0.125)}
    ${({ theme }) => theme.spacing(-0.125)};
`;

const SelectedOptionBase = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 0.25,
})`
  position: relative;
  border: solid 1px ${({ theme }) => theme.separator};
  border-radius: 999em;
  padding: 0 ${({ theme }) => theme.spacing(0.125)} 0
    ${({ theme }) => theme.spacing(0.5)};
  max-width: 100%;
  margin: ${({ theme }) => theme.spacing(0.125)}
    ${({ theme }) => theme.spacing(0.125)};
`;

const SelectedOption = ({ children, onDelete }) => (
  <SelectedOptionBase>
    <Text type="body">{children}</Text>
    <ButtonReset onClick={onDelete} type="button">
      <Stack>
        <IcRemoveCircle />
      </Stack>
    </ButtonReset>
  </SelectedOptionBase>
);

const Select = ({
  multiple = false,
  options,
  onChange,
  value,
  disabled,
  renderSelectedValue,
  renderOption,
  hasError,
  placeholder,
  label,
  containerStyle,
  shouldHideSelectedValues,
}) => {
  const dropdownRef = useRef(null);

  const filteredOptions = (options || []).filter(
    option =>
      !multiple ||
      !includes(
        (value || []).map(({ value }) => value),
        option?.value,
      ),
  );

  const selectedOption = find(options, option => option.value === value?.value);

  const handleSelectOption = option => {
    if (!multiple) {
      onChange(option);
      return;
    }

    onChange([...(value || []), option]);
  };

  const handleUnselectOption = option => {
    onChange(
      (value || []).filter(selectedOption => !isEqual(selectedOption, option)),
    );
  };

  useDeepCompareEffect(() => {
    dropdownRef.current?.close();
  }, [{ value }]);

  return (
    <FormField>
      {Boolean(label) && <FormLabel>{label}</FormLabel>}
      <Dropdown
        ref={dropdownRef}
        disabled={disabled}
        trigger={
          <Trigger
            hasError={hasError}
            disabled={disabled}
            style={containerStyle}
          >
            {multiple ? (
              shouldHideSelectedValues ? (
                <span />
              ) : (
                <SelectedOptions>
                  {(value || []).map(option => (
                    <SelectedOption
                      onDelete={e => {
                        e.stopPropagation();
                        handleUnselectOption(option);
                      }}
                    >
                      {renderOption(option)}
                    </SelectedOption>
                  ))}
                </SelectedOptions>
              )
            ) : (
              <Text>
                {Boolean(value ?? selectedOption)
                  ? renderSelectedValue(value ?? selectedOption)
                  : placeholder}
              </Text>
            )}

            <Stack>
              <IcArrowDown size={24} />
            </Stack>
          </Trigger>
        }
      >
        <PopoverContainer>
          <PopoverMenu style={containerStyle}>
            {filteredOptions.map((item, index) => (
              <PopoverItem key={index}>
                <PopoverItemButton
                  type="button"
                  onClick={() => handleSelectOption(item)}
                >
                  <Text type="body">{renderOption(item)}</Text>
                </PopoverItemButton>
              </PopoverItem>
            ))}
          </PopoverMenu>
        </PopoverContainer>
      </Dropdown>
    </FormField>
  );
};

Select.defaultProps = {
  renderOption: ({ label }) => label,
  renderSelectedValue: selectedOption => selectedOption?.label,
};

export default Select;
