import getSymbolFromCurrency from 'currency-symbol-map';
import { snakeCase, pick, orderBy } from 'lodash';
import paths from 'deepdash/paths';
import { format, startOfYesterday, startOfMonth, endOfMonth } from 'date-fns';
import fr from 'date-fns/locale/fr';
import Dinero from 'dinero.js';
import koedia from 'images/bedBanks/koedia.png';
import hresa from 'images/bedBanks/h-resa.png';
import expedia from 'images/bedBanks/expedia.png';

export const ERRORS = {
  REQUIRED: 'Ce champ est requis',
  TAKEN: 'Cette valeur est déjà prise',
  INVALID: 'Cette valeur n’est pas valide',
  EMAIL: 'Cette adresse mail n’est pas valide',
  EMAIL_NOT_FOUND: 'Cette adresse mail n’est pas enregistrée',
};

export const formatDate = (date, frmt) => {
  try {
    return format(new Date(date), frmt, { locale: fr });
  } catch (err) {
    return '';
  }
};

export const getYesterday = () => startOfYesterday();

export const getFirstAndLastDayOfMonth = () => {
  const date = new Date();
  return { first: startOfMonth(date), last: endOfMonth(date) };
};

export const remove = (list, itemValue) =>
  list.filter(item => item.value !== itemValue);

export const initialLocale = {
  value: 'en',
  label: 'EN',
  alpha2: 'gb',
};

export const getRole = role => {
  switch (role) {
    case 'koob_admin':
      return 'user:roles.koobAdmin';
    case 'dmc_admin':
      return 'user:roles.dmcAdmin';
    default:
      return 'user:roles.other';
  }
};

export const extractValues = (schema, values) => {
  const schemaPaths = paths(schema.default());
  return pick(values, schemaPaths);
};

export const constantCase = input => snakeCase(input).toUpperCase();

export const formatOrderCriteria = ({ order, direction }) => {
  if (!Boolean(order)) {
    return null;
  }

  return {
    column: constantCase(order),
    direction: constantCase(direction) || 'ASC',
  };
};

export const pluralize = (input, count) =>
  input
    .split(' ')
    .map(i => `${i}${count > 1 ? 's' : ''}`)
    .join(' ');

export const getActionsCellWidth = actionsCount =>
  24 * actionsCount + 8 * (actionsCount - 1) + 16 * 2;

export const readFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const sortFacilitiesByGroup = facilities => {
  let result = {};

  facilities.forEach(facility => {
    facility?.groups?.nodes?.forEach(group => {
      if (Boolean(result[group.id])) {
        result[group.id].facilities.push(facility);
      } else {
        result[group.id] = {
          group,
          facilities: [facility],
        };
      }
    });
  });
  return Object.values(result);
};

export const formatFacilitiesToGroupsFacilities = (
  facilities,
  othersLabel = 'Others',
) => {
  if (facilities?.length === 0) {
    return [];
  }

  const [facilitiesWithGroups, facilitiesWithoutGroups] = facilities.reduce(
    (acc, curr, index) => {
      const facility = { ...(curr?.node || curr) };
      const accIndex = facility?.groups?.nodes?.length > 0 ? 0 : 1;
      acc[accIndex].push({
        ...facility,
        index,
      });
      return acc;
    },
    [[], []],
  );

  const sortedFacilitiesByGroup = sortFacilitiesByGroup(facilitiesWithGroups);

  const reorderGroupsFacilities = orderBy(
    sortedFacilitiesByGroup,
    ['group.displayName'],
    ['asc'],
  );

  if (facilitiesWithoutGroups.length === 0) {
    return reorderGroupsFacilities;
  }

  const groupsFacilitiesAndOthers = reorderGroupsFacilities.concat([
    {
      group: { displayName: othersLabel, id: 'others' },
      facilities: facilitiesWithoutGroups,
    },
  ]);

  return groupsFacilitiesAndOthers;
};

export const getBedBankName = code => {
  switch (code) {
    case 'HRL':
      return 'H-resa';
    case 'RXP':
      return 'Expedia';
    case 'FEL':
      return 'Othyssia';
    default:
      return '';
  }
};

export const getBedBankLogo = code => {
  switch (code) {
    case 'HRL':
      return hresa;
    case 'RXP':
      return expedia;
    case 'FEL':
      return koedia;
    default:
      return '';
  }
};

export const formatCurrency = ({ amount, currency }) => {
  if (!amount && amount !== 0) {
    return '';
  }

  try {
    return Dinero({ amount: Math.round(amount * 100), currency })
      .setLocale('fr')
      .toFormat();
  } catch (err) {
    return amount;
  }
};

export const onlyDigitAndSpaceRegex = /^[0-9\s]*$/;

export const formatCurrencyLabel = currency =>
  `${currency.toUpperCase()}  ${getSymbolFromCurrency(currency)}`;
