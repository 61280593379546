import { useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { capitalize, last } from 'lodash';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { List, media, useMargaret, Stack, Dropdown } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import { fontStyles } from 'ui';
import { useRoutes } from 'hooks';
import { IcArrowDown } from 'components/icons';
import { useDeepCompareEffect } from 'react-use';

const shine = keyframes`
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 96px;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;

      ${media.tablet`
        display: none;
      `};
    `}
`;

const BreadcrumbContent = styled(List)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;

const Crumb = styled.li`
  display: none;
  display: flex;
  line-height: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;

  > svg {
    display: none;

    ${media.tablet`
      display: flex;
      margin-left: ${({ theme }) => theme.spacing(0.25)};
      margin-right: ${({ theme }) => theme.spacing(0.25)};
    `}
  }
`;

const Placeholder = styled.div`
  height: 14px;
  width: 96px;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.06),
    rgba(0, 0, 0, 0.04),
    rgba(0, 0, 0, 0.06) 40px
  );
  animation: ${shine} 2s linear infinite;
  border-radius: 2px;
`;

const CrumbLink = styled(NavLink)`
  ${fontStyles.bodyLarge};

  color: ${({ theme }) => theme.text};
  text-decoration: none;
  align-items: center;
  line-height: 1.5;
  display: none;

  ${media.tablet`
    display: flex;
  `}
`;

const CrumbLast = styled(CrumbLink)`
  display: flex;

  ${({ variant }) =>
    variant !== 'main' &&
    css`
      box-shadow: none;
      color: ${({ theme }) => theme.textLighter};
      padding-left: 0;
      padding-right: 0;
    `}
`;

const CrumbMain = styled.h1`
  ${fontStyles.h1Mega}

  margin: 0;
  line-height: 1;
`;

const CrumbMainLink = styled(Link)`
  color: inherit;
`;

const MobileTrigger = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.textLight};
`;

const BreadcrumbPopoverTriggerBase = styled.div`
  padding: ${({ theme }) => theme.spacing(0.5)};
  font-size: 20px;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const BreadcrumbPopoverTrigger = ({ icon = <IcArrowDown /> }) => (
  <BreadcrumbPopoverTriggerBase variant="primary">
    <Stack>{icon}</Stack>
  </BreadcrumbPopoverTriggerBase>
);

const Breadcrumb = ({ popoverElement, ignoredPaths = [], variant = null }) => {
  const { pathname, key } = useLocation();
  const { isMobile } = useMargaret();
  const {
    routesNames,
    pathsRegexesWithPlaceholder,
    pathsRegexesWithoutBreadcrumb,
  } = useRoutes();
  const urlChunks = pathname.split('/').filter(Boolean);
  const { t } = useTranslation('breadcrumb');
  const popoverRef = useRef();

  const getPathTranslation = path => {
    const chunks = path.split('/');
    const chunk = last(chunks);

    const routeCustomName = routesNames[`/${path}`];

    if (Boolean(routeCustomName)) {
      return routeCustomName;
    }

    if (t(path) !== path) {
      return t(path);
    }

    if (t(chunk) !== chunk) {
      return t(chunk);
    }

    for (const regex of pathsRegexesWithPlaceholder) {
      if (Boolean(path.match(regex))) {
        return <Placeholder />;
      }
    }

    return capitalize(chunk);
  };

  const chunks = urlChunks.reduce((acc, _, index) => {
    const path = urlChunks.slice(0, index + 1).join('/');
    for (const regex of ignoredPaths) {
      if (Boolean(path.match(regex))) {
        return acc;
      }
    }

    for (const regex of pathsRegexesWithoutBreadcrumb) {
      if (Boolean(path.match(regex))) {
        return acc;
      }
    }

    return [
      ...acc,
      {
        path: `/${path}`,
        text: getPathTranslation(path),
      },
    ];
  }, []);

  const pageTitle =
    typeof last(chunks)?.text === 'object' || !Boolean(last(chunks)?.text)
      ? 'Koob'
      : `${last(chunks)?.text} – Koob`;

  useDeepCompareEffect(() => {
    if (Boolean(popoverRef?.current)) {
      popoverRef.current.close();
    }
  }, [{ key }]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Stack direction="row" gutterSize={1} alignY="center">
        <Nav aria-label="Breadcrumb">
          {isMobile && chunks.length >= 1 && (
            <MobileTrigger>{last(chunks)?.text}</MobileTrigger>
          )}

          {!isMobile && (
            <BreadcrumbContent>
              {chunks.map(({ path, text }, index) => {
                const isLast = chunks.length - 1 === index;

                if (index === 0) {
                  return (
                    <Crumb key={index}>
                      <CrumbMain>
                        {isLast ? (
                          <span>{text}</span>
                        ) : (
                          <CrumbMainLink
                            to={path}
                            onClick={() => {
                              if (variant === 'booking') {
                                localStorage.removeItem('booking');
                              }
                            }}
                          >
                            {text}
                          </CrumbMainLink>
                        )}
                      </CrumbMain>
                    </Crumb>
                  );
                }

                return (
                  <Crumb isLast={isLast} key={index}>
                    {index !== 0 && <MdKeyboardArrowRight />}

                    {isLast ? (
                      <CrumbLast
                        as="span"
                        variant={index === 0 && 'main'}
                        aria-current="page"
                      >
                        {text}
                      </CrumbLast>
                    ) : (
                      <CrumbLink to={path} variant={index === 0 && 'main'}>
                        {text}
                      </CrumbLink>
                    )}
                  </Crumb>
                );
              })}
            </BreadcrumbContent>
          )}
        </Nav>

        {Boolean(popoverElement) && (
          <Dropdown ref={popoverRef} trigger={<BreadcrumbPopoverTrigger />}>
            {popoverElement}
          </Dropdown>
        )}
      </Stack>
    </>
  );
};

export default Breadcrumb;
