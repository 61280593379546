import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Stack } from '@tymate/margaret';
import logo from 'images/logo-koob.svg';
import { Container } from 'ui';
import { GET_HOTEL_KEEPER_ADMIN_BOOKING } from 'api/hotelKeeper';
import {
  BookingRecap,
  SubHeader,
  RespondBooking,
} from 'components/HotelKeeper';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Space = styled.div`
  flex: 1;
`;

const Logo = styled.img`
  align-self: start;
`;

const Header = styled(Stack).attrs({
  alignY: 'center',
})`
  padding: ${({ theme }) => theme.spacing(1.5)}
    ${({ theme }) => theme.spacing(2)};
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
`;

const HotelKeeper = () => {
  const { secret } = useParams();
  const { data, refetch } = useQuery(GET_HOTEL_KEEPER_ADMIN_BOOKING, {
    variables: {
      secret,
    },
  });
  const booking = data?.hotelKeeperAdminBooking;

  return (
    <Wrapper>
      <Header>
        <Logo src={logo} alt="KOOB" />
      </Header>
      <SubHeader booking={booking} />
      <Container>
        <BookingRecap booking={booking} />
      </Container>
      <Space />
      {booking?.state === 'sent' && (
        <RespondBooking refetch={refetch} booking={booking} />
      )}
    </Wrapper>
  );
};

export default HotelKeeper;
