import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink, Routes, Route } from 'react-router-dom';
import { IoEarthOutline, IoBriefcaseOutline } from 'react-icons/io5';
import { Scrollbars } from 'react-custom-scrollbars';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { MainNav, Stack } from '@tymate/margaret';
import {
  // IcDashboard,
  // IcCreditCard,
  // IcTicket,
  IcHotel,
  IcUsers,
  IcOrganization,
  IcTicket,
} from 'components/icons';
import Logo from 'components/icons/Logo';
import { useApp, useAuth } from 'hooks';
import { fontStyles, Text } from 'ui';

const SectionTitle = styled(Text).attrs({
  color: 'textLight',
  type: 'bodySmall',
  paddingHorizontal: 2,
})`
  text-transform: uppercase;
  font-weight: 600;
`;

const MainNavItems = styled(MainNav.Items).attrs({ gutterSize: 0.5 })`
  ${({ variant }) =>
    variant === 'nested' &&
    css`
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
    `}
`;

export const SidebarNavLink = styled(Stack).attrs({
  as: NavLink,
  gutterSize: 0.5,
  alignY: 'center',
})`
  ${fontStyles.bodySmall}

  color: ${({ theme }) => theme.textLight};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  transition: background 150ms ease, color 150ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.sidebarBackgroundHoverColor};
    color: ${({ theme }) => theme.primary};
  }

  &.active {
    color: ${({ theme }) => theme.primary};
  }

  ${({ variant }) =>
    variant !== 'accordion' &&
    css`
      &:hover,
      &.active {
        color: ${({ theme }) => theme.primary};
        background-color: ${({ theme }) => theme.sidebarBackgroundHoverColor};
      }
    `}

  ${({ variant }) =>
    variant === 'accordion' &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(-0.5)};
    `}

  ${({ variant }) =>
    variant === 'nested' &&
    css`
      padding-left: ${({ theme }) => theme.spacing(3)};
    `}

  svg {
    font-size: 1.5rem;
  }
`;

const MainSidebar = () => {
  const { isAllowedTo, currentOrganizationId } = useApp();
  const { t } = useTranslation('mainSidebar');
  const { logout } = useAuth();

  return (
    <MainNav style={{ zIndex: 11, paddingRight: 16 }}>
      <MainNav.Header alignX="center" size="full" marginTop={1}>
        <Logo />
      </MainNav.Header>

      <Scrollbars autoHide>
        <MainNav.MainContent
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <MainNavItems gutterSize={0.25}>
            {/* <MainNav.Item>
              <SidebarNavLink to="/" end>
                <IcDashboard />
                <span>{t('dashboard')}</span>
              </SidebarNavLink>
            </MainNav.Item> */}
            {/* <MainNav.Item>
              <SidebarNavLink to="billing">
                <IcCreditCard />
                <span>{t('billing')}</span>
              </SidebarNavLink>
            </MainNav.Item> */}

            {isAllowedTo('manageOrganizations') && (
              <MainNav.Item>
                <SidebarNavLink to="organizations">
                  <IcOrganization />
                  <span>{t('organizations')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            )}
            {isAllowedTo('readSelfOrganization') && (
              <MainNav.Item>
                <SidebarNavLink to={`organizations/${currentOrganizationId}`}>
                  <IcOrganization />
                  <span>{t('myOrganization')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            )}
            {isAllowedTo('readBookings') && (
              <MainNav.Item>
                <SidebarNavLink to="bookings">
                  <IcTicket />
                  <span>{t('bookings')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            )}

            <MainNav.Item>
              <SidebarNavLink to="hotels" variant="accordion">
                <IcHotel />
                <span>{t('hotels')}</span>
              </SidebarNavLink>
              <Routes>
                <Route
                  path="hotels/*"
                  element={
                    <MainNavItems variant="nested">
                      <MainNav.Item>
                        <SidebarNavLink to="catalog" variant="nested">
                          <span>{t('myCatalog')}</span>
                        </SidebarNavLink>
                      </MainNav.Item>
                      {isAllowedTo('createHotel') && (
                        <MainNav.Item>
                          <SidebarNavLink to="new" variant="nested">
                            <span>{t('searchNewHotel')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      )}
                    </MainNavItems>
                  }
                />
              </Routes>
            </MainNav.Item>

            {isAllowedTo('readUsers') && (
              <MainNav.Item>
                <SidebarNavLink to="users">
                  <IcUsers />
                  <span>{t('users')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            )}
            {isAllowedTo('readLocations') && (
              <MainNav.Item>
                <SidebarNavLink to="locations" variant="accordion">
                  <IoEarthOutline />
                  <span>{t('locations')}</span>
                </SidebarNavLink>
                <Routes>
                  <Route
                    path="locations/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="countries" variant="nested">
                            <span>{t('countries')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="cities" variant="nested">
                            <span>{t('cities')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}
          </MainNavItems>

          <SectionTitle paddingTop={2}>{t('settings')}</SectionTitle>

          <MainNavItems gutterSize={0.25}>
            {isAllowedTo('readHotelSettings') && (
              <MainNav.Item>
                <SidebarNavLink to="settings/hotels" variant="accordion">
                  <IcHotel />
                  <span>{t('hotelsSettings')}</span>
                </SidebarNavLink>

                <Routes>
                  <Route
                    path="settings/hotels/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="categories" variant="nested">
                            <span>{t('categories')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="styles" variant="nested">
                            <span>{t('styles')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="locations" variant="nested">
                            <span>{t('locations')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="accreditations" variant="nested">
                            <span>{t('accreditations')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink
                            to="general-categories"
                            variant="nested"
                          >
                            <span>{t('descriptions')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="dmc-categories" variant="nested">
                            <span>{t('dmcCategories')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="room-types" variant="nested">
                            <span>{t('roomTypes')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="bed-types" variant="nested">
                            <span>{t('bedTypes')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink
                            to="sustainable-clusters"
                            variant="nested"
                          >
                            <span>{t('sustainableClusters')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink
                            to="facilities-groups"
                            variant="nested"
                          >
                            <span>{t('facilitiesGroups')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}
            {isAllowedTo('readTravelerSettings') && (
              <MainNav.Item>
                <SidebarNavLink to="settings/travelers" variant="accordion">
                  <IoBriefcaseOutline />
                  <span>{t('travelersSettings')}</span>
                </SidebarNavLink>
                <Routes>
                  <Route
                    path="settings/travelers/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="traveler-kinds" variant="nested">
                            <span>{t('categories')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}
          </MainNavItems>

          <MainNavItems>
            <MainNav.Item>
              <SidebarNavLink
                to="traveler-kinds"
                onClick={logout}
                variant="accordion"
              >
                <RiLogoutBoxLine />
                <span>{t('signOut')}</span>
              </SidebarNavLink>
            </MainNav.Item>
          </MainNavItems>
        </MainNav.MainContent>
      </Scrollbars>
    </MainNav>
  );
};

export default MainSidebar;
