import { useCallback } from 'react';
import { useField } from 'formik';
import { Box, media, Stack } from '@tymate/margaret';
import styled from 'styled-components';
import { readFile } from 'utils';
import GalleryItemField from './GalleryItemField';
import Dropzone from 'components/Dropzone';
import { FormLabel } from 'ui';

const GalleryGrid = styled(Box)`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing()};
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  ${media.desktop`
    grid-template-columns: repeat(${({ maximumColumnsCount }) =>
      maximumColumnsCount}, 1fr);
  `}
`;

const GalleryField = ({ name, label, maximumColumnsCount = 2, disabled }) => {
  const [{ value }, , { setValue }] = useField({ name });

  const handleAddFiles = async files => {
    const newValue = await Promise.all(
      files.map(async file => {
        const imageUrl = await readFile(file);
        return {
          file,
          displayName: file.name,
          imageUrl,
        };
      }),
    );

    setValue([...value, ...newValue]);
  };

  const handleRemoveItem = useCallback(
    targetIndex => {
      setValue(value.filter((_, index) => index !== targetIndex));
    },
    [setValue, value],
  );

  return (
    <Stack size="full" direction="column">
      {Boolean(label) && <FormLabel>{label}</FormLabel>}
      <Stack size="full" gutterSize={0.5}>
        <GalleryGrid maximumColumnsCount={maximumColumnsCount}>
          {value.map((item, index) => (
            <GalleryItemField
              index={index}
              item={item}
              name={`${name}.${index}`}
              onDelete={handleRemoveItem}
              disabled={disabled}
            />
          ))}
          {!disabled && <Dropzone onDrop={handleAddFiles} />}
        </GalleryGrid>
      </Stack>
    </Stack>
  );
};

export default GalleryField;
