import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Stack } from '@tymate/margaret';
import { Text, Tab } from 'ui';

// TODO Filter by orgaID pour les others et dmc
const GET_BOOKINGS_COUNT_BY_HOTELS = gql`
  query getBookingsByHotels($withValidatedBookings: Boolean) {
    hotels(withValidatedBookings: $withValidatedBookings) {
      nodes {
        adminBookings {
          totalCount
        }
      }
    }
  }
`;

const TabBookingsByDay = ({ path }) => {
  const { t } = useTranslation('bookings');
  const { data } = useQuery(GET_BOOKINGS_COUNT_BY_HOTELS, {
    variables: { withValidatedBookings: true },
  });

  const totalBookingsCount = (data?.hotels?.nodes || [])
    .map(({ adminBookings }) => adminBookings?.totalCount || 0)
    .reduce((acc, curr) => acc + curr, 0);

  return (
    <Tab to={path} variant="bordered" size="large">
      <Stack size="full" alignY="center">
        <Stack direction="column" size="full" gutterSize={0.5}>
          <Text type="body">{t('byHotels')}</Text>
          <Stack alignY="center" gutterSize={0.5}>
            <Text type="h1Mega">{totalBookingsCount}</Text>
            <MdKeyboardArrowRight size={20} />
            <Text type="body">{`${data?.hotels?.nodes?.length || 0} ${t(
              'hotels',
            )}`}</Text>
          </Stack>
        </Stack>
        <MdKeyboardArrowRight size={36} />
      </Stack>
    </Tab>
  );
};

export default TabBookingsByDay;
