import { useState } from 'react';
import { ButtonReset, Stack } from '@tymate/margaret';
import { Button } from 'components';
import { MdDelete } from 'react-icons/md';
import { useFormikContext } from 'formik';
import { useDeepCompareEffect } from 'react-use';

const TogglableField = ({ idleElement, activeElement, onDelete, disabled }) => {
  const [isToggled, setIsToggled] = useState(false);
  const { initialValues } = useFormikContext();

  useDeepCompareEffect(() => {
    if (isToggled) {
      setIsToggled(false);
    }
  }, [{ initialValues }]);

  if (isToggled) {
    return (
      <Stack direction="column" size="full" gutterSize={0.5}>
        {activeElement}
        <Stack size="full" alignX="flex-end">
          <Button onClick={() => setIsToggled(false)} variant="primary">
            OK
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gutterSize={0.5}>
      <ButtonReset
        disabled={disabled}
        type="button"
        onClick={() => setIsToggled(true)}
        style={{ flex: 1 }}
      >
        {idleElement}
      </ButtonReset>

      {Boolean(onDelete) && !disabled && (
        <ButtonReset onClick={onDelete}>
          <MdDelete />
        </ButtonReset>
      )}
    </Stack>
  );
};

export default TogglableField;
