import { Stack } from '@tymate/margaret';
import { useField } from 'formik';
import { ErrorMessageWrapper, FormLabel, FormField } from 'ui';
import ToggleSwitch from './ToggleSwitch';
import styled from 'styled-components';

const GridStack = styled(Stack).attrs({
  alignY: 'center',
})`
  display: inline-grid;
  gap: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.25)};
  grid-template-columns: repeat(auto-fit, minmax(44px, auto));
`;

const ToggleSwitchField = ({ label, name, ...props }) => {
  const [{ value }, meta, { setValue }] = useField({ name });
  const hasError = Boolean(meta.error && meta.touched);

  return (
    <FormField>
      <FormLabel variant="bare">
        <GridStack>
          {Boolean(label) && <span>{label}</span>}
          <ToggleSwitch
            {...props}
            style={{ minWidth: 80 }}
            checked={value}
            hasError={hasError}
            onChange={() => setValue(!value)}
          />
        </GridStack>
        {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
      </FormLabel>
    </FormField>
  );
};

export default ToggleSwitchField;
