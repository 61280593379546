import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { MailTo } from 'ui';
import Avatar from 'components/Avatar';
import { upperFirst } from 'lodash';
import { IcMail, IcPhone } from 'components/icons';

const Name = styled.span`
  font-weight: 600;
`;

const Sublabel = styled.span`
  color: ${({ theme }) => theme.textLight};
  margin: 0;
`;

const AvatarAndName = ({ user }) => (
  <Stack direction="row" marginTop={0.5} gutterSize={0.5}>
    <Avatar
      firstName={user?.firstName}
      lastName={user?.lastName}
      imageUrl={encodeURI(user?.avatarUrl)}
    />

    <Stack direction="column">
      <Name>
        {upperFirst(user?.firstName)} {upperFirst(user?.lastName)}
      </Name>
      <Sublabel>{upperFirst(user?.job)}</Sublabel>

      <Stack direction="row" gutterSize={0.5}>
        {Boolean(user?.email) && (
          <MailTo href={`mailto:${user?.email}`}>
            <IcMail size={18} /> {user?.email}
          </MailTo>
        )}
        {Boolean(user?.phoneNumber) && (
          <MailTo href={`tel:${user?.phoneNumber}`}>
            <IcPhone size={18} /> {user?.phoneNumber}
          </MailTo>
        )}
      </Stack>
    </Stack>
  </Stack>
);

export default AvatarAndName;
