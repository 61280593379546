import { Select } from 'components/Fields';
import { Stack } from '@tymate/margaret';
import { useApp } from 'hooks';

const LocaleSwitcher = ({ queryLocale, updateQueryLocale, disabled }) => {
  const { locales } = useApp();

  return (
    <div>
      <Select
        disabled={disabled}
        containerStyle={{ minWidth: 80, width: 80 }}
        onChange={updateQueryLocale}
        value={queryLocale}
        options={locales}
        renderSelectedValue={({ label, alpha2 }) => (
          <Stack gutterSize={0.375} alignY="center">
            <span className={`flag-icon flag-icon-${alpha2}`} />
            <span>{label}</span>
          </Stack>
        )}
        renderOption={({ label, alpha2 }) => (
          <Stack gutterSize={0.375} alignY="center">
            <span className={`flag-icon flag-icon-${alpha2}`} />
            <span>{label}</span>
          </Stack>
        )}
      />
    </div>
  );
};

export default LocaleSwitcher;
