import MultiSearchableSelect from './MultiSearchableSelect';
import { useField } from 'formik';
import { FormField, FormLabel, ErrorMessageWrapper } from 'ui/forms';
import { Stack } from '@tymate/margaret';
import { isArray, isEqual } from 'lodash';

const formatError = error => {
  if (isArray(error)) {
    return error.filter(Boolean)?.[0];
  }
  return error;
};

const MultiSearchableSelectField = ({
  name,
  label,
  rightLabel,
  onChange,
  ...props
}) => {
  const [{ value }, { touched, error }, { setValue }] = useField({ name });
  const hasError = Boolean(touched) && Boolean(formatError(error));

  return (
    <FormField>
      <Stack alignX="space-between" alignY="center">
        {label && <FormLabel>{label}</FormLabel>}
        {rightLabel}
      </Stack>
      <MultiSearchableSelect
        {...props}
        excludedIds={(value || []).map(({ id }) => id)}
        onChange={value => setValue(value)}
        value={value}
        hasError={hasError}
        error={hasError && error}
        onSelectOption={option => {
          if (Boolean(value)) {
            setValue([...value, option]);
          } else {
            setValue([option]);
          }
        }}
        onUnselectOption={option =>
          setValue(value.filter(valueChunk => !isEqual(option, valueChunk)))
        }
      />
      {Boolean(hasError) && (
        <ErrorMessageWrapper>{formatError(error)}</ErrorMessageWrapper>
      )}
    </FormField>
  );
};

export default MultiSearchableSelectField;
