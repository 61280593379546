import styled, { useTheme } from 'styled-components';
import { Stack } from '@tymate/margaret';
import { Input } from 'ui/forms';
import { Button } from 'components';
import { IcAddCircle, IcRemoveCircle } from 'components/icons';

const NumberInput = styled(Input)`
  max-width: 80px;
  text-align: center;
  width: 4em;
`;

const Stepper = ({
  value,
  min = 0,
  max = Number.MAX_SAFE_INTEGER,
  disabled,
  onChange,
}) => {
  const theme = useTheme();
  const MAX_DIGITS = 5;

  const handleChange = newValue => {
    if (!Number.isInteger(value)) {
      return;
    }
    if (newValue > value) {
      onChange(Math.min(max, newValue));
    } else if (newValue < value) {
      onChange(Math.max(min, newValue));
    }
  };

  return (
    <Stack alignY="center" gutterSize={0.5}>
      {!disabled && (
        <Button
          onClick={() => handleChange(value - 1)}
          type="button"
          icon={<IcRemoveCircle size={24} color={theme.text} />}
        />
      )}
      <NumberInput
        type="text"
        pattern="[0-9]*"
        onInput={event => {
          const newInput =
            event.target.validity.valid &&
            event.target.value.length <= MAX_DIGITS
              ? event.target.value
              : value;
          if (newInput) {
            handleChange(parseInt(newInput));
          } else {
            handleChange(0);
          }
        }}
        value={value}
        disabled={disabled}
      />
      {!disabled && (
        <Button
          onClick={() => {
            if (!Boolean(value)) {
              onChange(1);
            } else if (Number.isInteger(value)) {
              onChange(Math.min(max, value + 1));
            }
          }}
          type="button"
          icon={<IcAddCircle size={24} color={theme.text} />}
        />
      )}
    </Stack>
  );
};

export default Stepper;
