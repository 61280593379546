import { useField } from 'formik';
import DateRangePicker from './DateRangePicker';
import { FormLabel, ErrorMessageWrapper, FormField } from 'ui/forms';
import { Stack } from '@tymate/margaret';
import { formatDate } from 'utils';

const DateRangePickerField = ({
  placeholder,
  label,
  name,
  isDateTime = false,
  ...props
}) => {
  const [field, meta, { setValue }] = useField({ name });
  const hasError = Boolean(meta.error && meta.touched);

  return (
    <FormField>
      <Stack direction="column" size="full" alignX="stretch" gutterSize={0.5}>
        {Boolean(label) && <FormLabel>{label}</FormLabel>}

        <DateRangePicker
          hasError={hasError}
          placeholder={placeholder}
          {...field}
          onChange={value => {
            if (isDateTime) {
              setValue(value);
            } else {
              const start = formatDate(value?.[0], 'yyyy-MM-dd');
              const end = formatDate(value?.[1], 'yyyy-MM-dd');
              setValue([start, end]);
            }
          }}
          {...props}
        />

        {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
      </Stack>
    </FormField>
  );
};

export default DateRangePickerField;
