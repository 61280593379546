import { Stack } from '@tymate/margaret';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Button, Modal } from 'components';
import { useSnack, useError } from 'hooks';
import { UPDATE_HOTEL_KEEPER_ADMIN_BOOKING } from 'api/hotelKeeper';
import {
  ConfirmAdminBookingForm,
  RefuseAdminBookingForm,
  OtherProposalAdminBookingForm,
} from 'components/Forms/HotelKeeper';
import { Text } from 'ui';

const FooterBase = styled(Stack).attrs({
  size: 'full',
  alignX: 'center',
  alignY: 'center',
  gutterSize: 1,
})`
  box-shadow: 0 -1px 2px ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing()};
  background-color: rgba(245, 112, 10, 0.04);
`;

const Space = styled.div`
  height: 1rem;
`;

const CanceledModal = ({ handleSubmit, setUpdateState }) => {
  const { t } = useTranslation('hotelKeeper');
  const theme = useTheme();

  return (
    <Modal
      title={t('refuseBooking')}
      isOpen
      onRequestClose={() => setUpdateState(null)}
    >
      <Text fontWeight={700} type="h3" color="text">
        {t('misc:warning')}
      </Text>
      <Space />
      <div style={{ color: theme.danger }}>
        <Trans i18nKey="refusedModal">
          You are about to <strong>refuse this booking</strong>, are you sure
          you want to continue?
        </Trans>
      </div>
      <RefuseAdminBookingForm
        onSubmit={handleSubmit}
        onClose={() => setUpdateState(null)}
      />
    </Modal>
  );
};

const OtherProposalModal = ({ handleSubmit, setUpdateState, booking }) => {
  const { t } = useTranslation('hotelKeeper');

  return (
    <Modal
      size="medium"
      title={t('otherProposal')}
      isOpen
      onRequestClose={() => setUpdateState(null)}
    >
      <Stack direction="column">
        <Text color="textLighter">{t('otherProposalInstruction1')}</Text>
        <Text color="textLighter">{t('otherProposalInstruction2')}</Text>
      </Stack>
      <OtherProposalAdminBookingForm
        onSubmit={handleSubmit}
        onClose={() => setUpdateState(null)}
        booking={booking}
      />
    </Modal>
  );
};

const ConfirmedModal = ({ handleSubmit, setUpdateState }) => {
  const { t } = useTranslation('hotelKeeper');
  const theme = useTheme();

  return (
    <Modal
      title={t('confirmBooking')}
      isOpen
      onRequestClose={() => setUpdateState(null)}
    >
      <div style={{ color: theme.success }}>
        <Trans i18nKey="confirmModal">
          You are about to <strong>confirm this booking</strong>, are you sure
          you want to continue?
        </Trans>
      </div>

      <ConfirmAdminBookingForm
        onSubmit={handleSubmit}
        onClose={() => setUpdateState(null)}
      />
    </Modal>
  );
};

const RespondBooking = ({ refetch, booking }) => {
  const { t } = useTranslation('hotelKeeper');
  const [updateState, setUpdateState] = useState(null);
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();
  const { secret } = useParams();
  const [updateAdminBooking] = useMutation(UPDATE_HOTEL_KEEPER_ADMIN_BOOKING);

  const handleSubmit = async values => {
    try {
      await updateAdminBooking({
        variables: {
          input: { secret: secret, state: updateState, ...values },
        },
      });

      notify(t(`notify.${updateState}`));
      refetch();
    } catch (err) {
      sendErrorSnack(err);
    }
  };

  return (
    <>
      {updateState === 'refuse' && (
        <CanceledModal
          setUpdateState={setUpdateState}
          updateState={updateState}
          handleSubmit={handleSubmit}
        />
      )}

      {updateState === 'confirm' && (
        <ConfirmedModal
          setUpdateState={setUpdateState}
          updateState={updateState}
          handleSubmit={handleSubmit}
        />
      )}

      {updateState === 'other_proposal' && (
        <OtherProposalModal
          setUpdateState={setUpdateState}
          updateState={updateState}
          handleSubmit={handleSubmit}
          booking={booking}
        />
      )}

      <FooterBase>
        <Button
          type="button"
          variant="danger"
          onClick={() => setUpdateState('refuse')}
        >
          {t('refuse')}
        </Button>
        <Button
          type="button"
          variant="simple"
          onClick={() => setUpdateState('other_proposal')}
        >
          {t('otherProposal')}
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={() => setUpdateState('confirm')}
        >
          {t('confirm')}
        </Button>
      </FooterBase>
    </>
  );
};

export default RespondBooking;
