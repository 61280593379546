import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Card as RawCard, Text } from 'ui';
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
import { ButtonReset, Stack } from '@tymate/margaret';
import { useFormikContext } from 'formik';
import { FormField, FormLabel } from 'ui/forms';
import { get, groupBy, keys } from 'lodash';
import { IcAddCircle, IcAddCircleMulti, IcCheckMark } from 'components/icons';
import Search from 'components/Search';
import { useDebounce } from 'react-use';

const RegionLine = styled(ButtonReset)`
  ${({ isChecked, theme }) =>
    isChecked &&
    `
      background-color: ${theme.background};
    `}
`;

const CountryLine = styled(Stack).attrs({
  paddingVertical: 0.75,
  paddingLeft: 0.75,
  paddingRight: 1.25,
  size: 'full',
  alignY: 'center',
  alignX: 'space-between',
})`
  ${({ isChecked, theme }) =>
    isChecked &&
    `
      background-color: ${theme.background};
    `}
`;

const GET_REGIONS = gql`
  query getRegions($search: String, $after: String) {
    regionsSearch(query: $search, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          title
          country {
            id
            title
            alpha2
            regions {
              totalCount
            }
          }
        }
      }
    }
  }
`;

const GET_COUNTRIES = gql`
  query getCountries($search: String) {
    countries(title: $search, first: 195) {
      edges {
        node {
          id
          alpha2
          title
          regions(first: 100) {
            nodes {
              id
              title
            }
            totalCount
          }
        }
      }
    }
  }
`;

const Card = styled(RawCard)`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 0;
`;

const CountryWrapper = styled(Stack).attrs({
  size: 'full',
})`
  + * > * {
    border-top: 1px solid ${({ theme }) => theme.separator};
  }
`;

const CollapseButton = styled(ButtonReset).attrs({ type: 'button' })`
  color: ${({ theme }) => theme.secondary};
`;

const Sublabel = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.textLight};
`;

const Regions = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
})``;

const Country = ({ country, name }) => {
  const { t } = useTranslation('misc');
  const [isExpanded, setIsExpanded] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();

  const formatAggregateValue = input => {
    if (country?.regions?.totalCount === 0) {
      return false;
    }
    const countryRegionIds = (country?.regions?.nodes || []).map(
      ({ id }) => id,
    );
    const filledCountryRegionIds = countryRegionIds.filter(id =>
      Boolean(input?.[id]),
    );
    if (filledCountryRegionIds.length !== countryRegionIds.length) {
      return false;
    }

    const countryValues = countryRegionIds.map(id => input?.[id]);

    if (
      countryValues.filter(value => Boolean(value)).length ===
      country?.regions?.totalCount
    ) {
      return true;
    }

    return false;
  };

  const isAllChecked = formatAggregateValue(get(values, name));

  const handleToggleCountry = () => {
    const newFieldValue = (country.regions.nodes || []).reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: !isAllChecked,
      }),
      {},
    );

    setFieldValue(name, { ...values?.[name], ...newFieldValue });
  };

  const handleToggleRegion = id => {
    setFieldValue(`${name}.${id}`, !values?.regions?.[id]);
  };

  return (
    <CountryWrapper>
      <Regions>
        <CountryLine isChecked={isAllChecked}>
          <Stack gutterSize={0.5} alignY="center">
            <Stack alignY="center" style={{ height: 24 }}>
              <span
                className={`flag-icon flag-icon-${country.alpha2.toLowerCase()}`}
              />
            </Stack>

            <Text type="body" fontWeight="600">
              {country?.title} (
              {t('user:region', { count: country?.regions?.totalCount })})
            </Text>
          </Stack>

          <Stack alignY="center" gutterSize={0.75}>
            <CollapseButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? t('less') : t('more')}
            </CollapseButton>
            <Stack alignX="flex-end">
              <ButtonReset onClick={handleToggleCountry} type="button">
                {isAllChecked ? (
                  <IcCheckMark size={24} color={theme.textLight} />
                ) : (
                  <IcAddCircleMulti size={24} color={theme.secondary} />
                )}
              </ButtonReset>
            </Stack>
          </Stack>
        </CountryLine>

        {isExpanded && (
          <Stack direction="column" size="full">
            {(country?.regions?.nodes || []).map(region => (
              <RegionLine
                onClick={() => handleToggleRegion(region?.id)}
                style={{ width: '100%' }}
                type="button"
                isChecked={values?.regions?.[region.id]}
                key={region?.id}
              >
                <Stack
                  size="full"
                  alignX="space-between"
                  alignY="center"
                  paddingVertical={0.25}
                  paddingRight={1.25}
                  paddingLeft={2.5}
                >
                  <div>{region?.title}</div>
                  <Stack alignX="flex-end">
                    {values?.regions?.[region.id] ? (
                      <IcCheckMark size={24} color={theme.textLight} />
                    ) : (
                      <IcAddCircle size={24} color={theme.secondary} />
                    )}
                  </Stack>
                </Stack>
              </RegionLine>
            ))}
          </Stack>
        )}
      </Regions>
    </CountryWrapper>
  );
};

const RegionsSelectorField = ({ label, sublabel, name }) => {
  const [search, setSearch] = useState();
  const [debouncedSearch, setDebouncedSearch] = useState();
  const { data: countriesData } = useQuery(GET_COUNTRIES, {
    variables: { search: debouncedSearch },
  });
  const { data: regionsData } = useQuery(GET_REGIONS, {
    variables: { search: debouncedSearch },
    skip: !Boolean(debouncedSearch),
  });

  const regionsCountries = groupBy(
    regionsData?.regionsSearch?.edges || [],
    ({ node }) => node?.country?.id,
  );

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const countries = Boolean(debouncedSearch)
    ? keys(regionsCountries).map(id => {
        const regions = regionsCountries[id];
        const country = regions?.[0]?.node?.country;

        return {
          __typename: 'CountryEdge',
          node: {
            __typename: 'Country',
            ...country,
            regions: {
              nodes: regions.map(({ node }) => ({ ...node })),
              __typename: 'RegionConnection',
              totalCount: regions.length,
            },
          },
        };
      })
    : countriesData?.countries?.edges ?? [];

  return (
    <FormField>
      {Boolean(label) && <FormLabel>{label}</FormLabel>}
      {Boolean(sublabel) && <Sublabel>{sublabel}</Sublabel>}

      <Stack direction="column" gutterSize={1}>
        <Search value={search} onChange={setSearch} />

        {countries.length > 0 && (
          <Card>
            <Stack direction="column">
              {countries.map(({ node }) => (
                <Country key={node?.id} country={node} name={name} />
              ))}
            </Stack>
          </Card>
        )}
      </Stack>
    </FormField>
  );
};

export default RegionsSelectorField;
