import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import IcBasicDeal from 'components/icons/IcBasicDeal';
import IcLastMinute from 'components/icons/IcLastMinute';
import IcEarlyBooker from 'components/icons/IcEarlyBooker';

const PROMOTION_ICONS = {
  basic_deal: <IcBasicDeal size={12} />,
  early_booker: <IcEarlyBooker size={12} />,
  last_minute: <IcLastMinute size={12} />,
};

const Promotion = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 0.25,
})`
  font-size: 0.75rem;
  padding: ${({ theme }) => theme.spacing(0.25)};
  border: 1px solid ${({ theme }) => theme.green};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  color: ${({ theme }) => theme.green};
  background-color: ${({ theme }) => theme.greenLight};
`;

const HotelRoomPromotion = ({ name, kind }) => {
  return (
    <Promotion>
      <div>{PROMOTION_ICONS[kind]}</div>
      <div>{name}</div>
    </Promotion>
  );
};

export default HotelRoomPromotion;
