import SearchableSelect from './SearchableSelect';
import { useField } from 'formik';
import { FormField, FormLabel, ErrorMessageWrapper } from 'ui/forms';
import { Stack } from '@tymate/margaret';

const SearchableSelectField = ({
  name,
  label,
  rightLabel,
  onChange,
  onAfterChange,
  ...props
}) => {
  const [{ value }, { touched, error }, { setValue }] = useField({ name });
  const hasError = Boolean(touched) && Boolean(error);

  return (
    <FormField>
      <Stack alignX="space-between" alignY="center">
        {label && <FormLabel>{label}</FormLabel>}
        {rightLabel}
      </Stack>
      <SearchableSelect
        {...props}
        onAfterChange={onAfterChange}
        onChange={value => setValue(value)}
        value={value}
        hasError={hasError}
      />
      {hasError && <ErrorMessageWrapper>{error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default SearchableSelectField;
