import { Tag } from 'ui';
import { Tooltip } from 'components';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { IoWarningOutline } from 'react-icons/io5';

const getVariantDependingOnState = state => {
  const variantByState = {
    gray: ['draft'],
    blue: ['sent'],
    primary: ['pending'],
    green: ['confirmed'],
    red: ['refused', 'canceled'],
  };

  for (const variant in variantByState) {
    if (variantByState[variant].includes(state)) {
      return { variant, state };
    }
  }
  return { variant: 'primary', state };
};

const BookingState = ({ state }) => {
  const theme = useTheme();
  const { t } = useTranslation('bookings');
  const tagInfo = getVariantDependingOnState(state);

  return (
    <Tooltip
      tip={t(`tooltip.${state}`)}
      color="purple"
      hasArrow={false}
      position="left"
    >
      <Tag variant={tagInfo.variant}>
        {state === 'pending' && (
          <IoWarningOutline size={16} color={theme.primary} />
        )}
        {t(tagInfo.state)}
      </Tag>
    </Tooltip>
  );
};

export default BookingState;
