import { useQuery } from '@apollo/client';
import { useDeepCompareEffect } from 'react-use';
import { useRoutes } from 'hooks';

const useSetBreadcrumbName = ({ path, query, variables, formatData }) => {
  const { setRouteName } = useRoutes();
  const { data } = useQuery(query, {
    variables,
  });

  useDeepCompareEffect(() => {
    setRouteName({
      path,
      name: formatData(data),
    });
  }, [{ path, data }]);

  return null;
};

export default useSetBreadcrumbName;
