import { useField } from 'formik';
import { SegmentedControl, Stack } from '@tymate/margaret';
import { FormLabel, ErrorMessageWrapper, FormField } from 'ui/forms';

const SegmentedControlField = ({ label, options, ...props }) => {
  const [field, meta, { setValue }] = useField(props);

  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  return (
    <FormField style={{ flex: 0 }}>
      {label && <FormLabel>{label}</FormLabel>}
      <Stack>
        <SegmentedControl
          options={options}
          onSelect={setValue}
          {...field}
          {...props}
          hasError={hasError}
        />
      </Stack>
      {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default SegmentedControlField;
