import { useNavigate } from 'react-router-dom';
import { Stack } from '@tymate/margaret';
import { Button } from 'components';
import { FormGroup } from 'ui/forms';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/Fields';
import PasswordStrength, {
  validatePassword,
} from 'components/PasswordStrength';
import { useSearchParams, useSnack, useError } from 'hooks';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthLayout from 'components/AuthLayout';

const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      id
      errors {
        id
        message
      }
    }
  }
`;

const ResetPassword = () => {
  const [{ confirmation_token: confirmationToken }] = useSearchParams();
  const { t } = useTranslation(['auth', 'errors']);
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();
  const navigate = useNavigate();

  const [resetPassword] = useMutation(RESET_PASSWORD);

  const handleResetPassword = async values => {
    try {
      const { data } = await resetPassword({
        variables: { input: values },
      });

      if (data?.resetPassword?.errors?.length > 0) {
        throw data?.resetPassword?.errors?.[0];
      }

      notify(t('resetPassword.succeeded'));
      navigate('/login');
    } catch (err) {
      sendErrorSnack(err);
    }
  };
  return (
    <AuthLayout
      title={t('resetPassword.title')}
      subtitle={t('resetPassword.subtitle')}
    >
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: '',
          confirmationToken,
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(t('errors:required')),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password')], t('errors:passwordDoNotMatch'))
            .required(t('errors:required')),
        })}
        validate={validatePassword}
        onSubmit={handleResetPassword}
      >
        {({ isSubmitting, values, touched }) => (
          <Form>
            <FormGroup>
              <div>
                <TextField
                  type="password"
                  label={t('password')}
                  name="password"
                  autoComplete="off"
                />
                <PasswordStrength
                  password={values.password}
                  isTouched={touched.password}
                />
              </div>
              <TextField
                type="password"
                id="login-username"
                label={t('passwordConfirmation')}
                name="passwordConfirmation"
              />
            </FormGroup>
            <Stack marginTop={1.5}>
              <Button
                type="submit"
                variant="primaryBig"
                size="full"
                isLoading={isSubmitting}
              >
                {t('resetPassword.button')}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default ResetPassword;
