import { Stack } from '@tymate/margaret';
import { useTheme } from 'styled-components';
import { useSearchParams } from 'hooks';
import { IcExpand, IcArrowDown } from 'components/icons';
import { List } from 'react-movable';
import IcDrag from './icons/IcDrag';
import LocationAwareSearch from 'components/LocationAwareSearch';
import EmptyState from 'components/EmptyState';
import { EmptyStateWrapper } from 'ui';
import { useTranslation } from 'react-i18next';
import {
  TableWrapper,
  Table,
  TBody,
  THead,
  Th,
  Td,
  Tr,
  ColumnHeader,
  Content,
} from 'ui/tables';

const ASC = 'asc';

export const getNextSearchParams = ({ column, searchParams }) => {
  let { order, direction, ...rest } = searchParams;

  if (!Boolean(order) || column !== order) {
    return { ...rest, order: column, direction: 'asc' };
  }

  if (direction === 'asc') {
    return { ...rest, order: column, direction: 'desc' };
  }

  return rest;
};

const DataTable = ({
  headings = [],
  data,
  minWidth,
  actions,
  onMoveItem,
  filterSelect,
  isEmpty,
  isSearchable = true,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isDraggable = Boolean(onMoveItem);
  const { t } = useTranslation('dataTable');

  const formattedHeadings = isDraggable
    ? [{ width: '36px', slug: 'drag', variant: 'bareRight' }, ...headings]
    : headings;

  const formattedData = isDraggable
    ? data.map(chunk => ({
        ...chunk,
        drag: { render: () => <IcDrag size={20} color={theme.textLighter} /> },
      }))
    : data;

  const handleReorder = column => {
    setSearchParams(getNextSearchParams({ searchParams, column }));
  };

  const shownHeadings = formattedHeadings.filter(({ isHidden }) => !isHidden);

  const handleMoveItem = ({ oldIndex, newIndex }) => {
    if (!Boolean(onMoveItem)) {
      return null;
    }
    const movedItem = formattedData[oldIndex];
    onMoveItem({ movedItem, newIndex });
  };

  return (
    <Stack direction="column" size="full" gutterSize={1}>
      <Stack alignY="center" gutterSize={1} alignX="space-between" size="full">
        <Stack alignY="center" gutterSize={1}>
          {isSearchable && <LocationAwareSearch name="search" />}
          {filterSelect}
        </Stack>
        <div>{actions}</div>
      </Stack>
      {isEmpty ? (
        <EmptyStateWrapper>
          <EmptyState variant="full">{t('emptyStateMessage')}</EmptyState>
        </EmptyStateWrapper>
      ) : (
        <TableWrapper variant="bordered">
          <Table style={{ minWidth }}>
            <THead>
              <Tr>
                {shownHeadings.map(
                  ({
                    slug,
                    label,
                    cannotBeReordered,
                    variant,
                    orderCriteria,
                    width,
                    ...props
                  }) => {
                    const isActive =
                      (Boolean(orderCriteria) &&
                        searchParams?.order === orderCriteria) ||
                      searchParams?.order === slug;

                    return (
                      <Th
                        key={slug}
                        {...props}
                        variant={variant}
                        style={{ ...props.style, width }}
                      >
                        {Boolean(label) && (
                          <ColumnHeader
                            disabled={cannotBeReordered}
                            isActive
                            svgShouldGetUpsideDown={
                              isActive && searchParams?.direction === ASC
                            }
                            type="button"
                            onClick={() => handleReorder(orderCriteria || slug)}
                          >
                            <Stack alignY="center" gutterSize={0.5}>
                              <span>{label}</span>
                              {cannotBeReordered ? null : isActive ? (
                                <IcArrowDown />
                              ) : (
                                <Stack>
                                  <IcExpand
                                    style={{
                                      color: theme.textLighter,
                                    }}
                                  />
                                </Stack>
                              )}
                            </Stack>
                          </ColumnHeader>
                        )}
                      </Th>
                    );
                  },
                )}
              </Tr>
            </THead>

            {isDraggable && (
              <List
                values={formattedData}
                onChange={handleMoveItem}
                renderList={({ children, props }) => (
                  <TBody {...props}>{children}</TBody>
                )}
                renderItem={({ isDragged, value, props }) => {
                  return (
                    <Tr
                      isDragged={isDragged}
                      {...props}
                      style={{ ...props.style }}
                    >
                      {shownHeadings.map(({ slug, variant, ...props }) => (
                        <Td
                          key={slug}
                          variant={variant}
                          isLight={slug === 'id' || slug === 'organization'}
                          {...props}
                        >
                          <Content {...value[slug]} />
                        </Td>
                      ))}
                    </Tr>
                  );
                }}
              />
            )}

            {!isDraggable && (
              <TBody>
                {formattedData.map((row, index) => (
                  <Tr key={index}>
                    {shownHeadings.map(({ slug, variant, ...props }) => (
                      <Td
                        key={slug}
                        variant={variant}
                        isLight={slug === 'id' || slug === 'organization'}
                        {...props}
                      >
                        <Content {...row[slug]} />
                      </Td>
                    ))}
                  </Tr>
                ))}
              </TBody>
            )}
          </Table>
        </TableWrapper>
      )}
    </Stack>
  );
};

export default DataTable;
