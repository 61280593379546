import { useAuth } from 'hooks';
import { useQuery, gql } from '@apollo/client';
import { AppContext } from '../contexts';

const USER_QUERY = gql`
  query {
    currentUser {
      id
      firstName
      lastName
      email
      role
      organizations {
        nodes {
          id
          displayName
        }
      }
    }
  }
`;

const GET_LOCALES = gql`
  query getLocales {
    __type(name: "LocaleValues") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;

const permissions = {
  inviteUser: ['koob_admin', 'dmc_admin'],
  manageOrganizations: ['koob_admin'],
  readSelfOrganization: ['dmc_admin', 'other'],
  manageSelfOrganization: ['dmc_admin'],
  manageSelfOrganizationUsers: ['koob_admin', 'dmc_admin'],
  discardOrganizations: ['koob_admin'],
  readUsers: ['koob_admin'],
  manageUsers: ['koob_admin'],
  readLocations: ['koob_admin', 'dmc_admin', 'other'],
  manageLocations: ['koob_admin'],
  manageHotelSettings: ['koob_admin'],
  readHotelSettings: ['koob_admin', 'dmc_admin', 'other'],
  readTravelerSettings: ['koob_admin', 'dmc_admin', 'other'],
  manageTravelerSettings: ['koob_admin'],
  createHotel: ['dmc_admin', 'other'],
  readHotel: ['dmc_admin', 'other'],
  readBookings: ['koob_admin', 'dmc_admin', 'other'],
  manageBookings: ['dmc_admin', 'other'],
  deleteBookings: ['dmc_admin'],
};

const AppProvider = ({ children }) => {
  const { accessToken } = useAuth();
  // const client = useApolloClient();
  // const [queryLocale, setQueryLocale] = useState(
  //   localStorage.getItem('queryLocale') || 'en',
  // );

  const { data } = useQuery(USER_QUERY, {
    fetchPolicy: 'network-only',
    skip: !Boolean(accessToken),
  });

  const currentUserRole = data?.currentUser?.role;

  const { data: localesData } = useQuery(GET_LOCALES);

  const locales = localesData?.__type?.enumValues.map(
    ({ name, description }) => ({
      value: name,
      label: name.toUpperCase(),
      alpha2: description.toLowerCase(),
    }),
  );

  // const handleUpdateQueryLocale = locale => {
  //   localStorage.setItem('queryLocale', locale);
  //   setQueryLocale(locale);
  // };

  const canManageUser = targetUser => {
    const targetUserRole = targetUser?.role || 'other';

    if (targetUser?.id === data?.currentUser?.id) {
      return true;
    }

    switch (currentUserRole) {
      case 'koob_admin':
        return true;
      case 'dmc_admin':
        switch (targetUserRole) {
          case 'other':
            return true;
          default:
            return false;
        }
      case 'other':
      default:
        return false;
    }
  };

  const isAllowedTo = name =>
    (permissions[name] || []).indexOf(currentUserRole) !== -1;

  // useDeepCompareEffect(() => {
  //   client.resetStore();
  // }, [{ queryLocale }]);

  if (!Boolean(data?.currentUser)) {
    return null;
  }

  return (
    <AppContext.Provider
      value={{
        user: data?.currentUser,
        currentUserRole,
        currentOrganizationId: data?.currentUser?.organizations?.nodes?.[0]?.id,
        currentOrganization: data?.currentUser?.organizations?.nodes?.[0],
        canManageUser,
        isAllowedTo,
        locales,
        // queryLocale,
        // updateQueryLocale: handleUpdateQueryLocale,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
