import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Legend, Stack } from '@tymate/margaret';
import { DateRange } from 'components';
import HotelDetailCard from 'components/HotelKeeper/HotelDetailCard';
import { IcOrganization } from 'components/icons';
import { IoMoonOutline, IoPeopleOutline } from 'react-icons/io5';
import { FiUsers } from 'react-icons/fi';
import { IcHotel } from 'components/icons';
import UI, {
  RoomTag,
  CrossedPrice,
  NoteCard,
  TourOperator,
} from 'components/BookingTunnel/UIComponents';
import { MdMailOutline, MdPhone, MdCalendarToday } from 'react-icons/md';
import { CardTitle, SectionCard, Text } from 'ui';
import HotelRoomPromotion from 'components/BookingTunnel/HotelRoomPromotion';
import { formatCurrency, formatDate } from 'utils';
import { sum } from 'lodash';

const ItemCard = styled(Stack).attrs({
  size: 'full',
  padding: 0.5,
  gutterSize: 0.5,
})`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const DetailsCard = ({ index, composition, currency, state }) => {
  const { t } = useTranslation(['bookings', 'bookingForm', 'misc']);
  const theme = useTheme();
  const hotelRoom = composition?.hotelRoom;
  const travelers = composition?.travelers ?? [];
  const travelersAmount = travelers?.length ?? 0;
  const bedRooms = composition?.bedCompositions
    ?.map(bedCompo => `${bedCompo.quantity} ${bedCompo.label}`)
    ?.join(', ');

  return (
    <UI.DetailsSectionCard key={index}>
      <Stack
        direction="row"
        gutterSize={1}
        alignY="start"
        style={{
          marginBottom: theme.spacing(0.5),
          justifyContent: 'space-between',
        }}
      >
        <UI.CardTitle as="h2" style={{ width: 'fit-content' }}>
          {hotelRoom?.roomDescription ?? hotelRoom?.displayName ?? 'N/A'}
        </UI.CardTitle>
        <Stack direction="column" alignX="end">
          <Stack direction="row" gutterSize={1}>
            {composition?.dmcPriceWithoutPromo !== composition?.dmcPrice && (
              <CrossedPrice type="bodyLarge">
                {formatCurrency({
                  amount: composition?.dmcPriceWithoutPromo,
                  currency,
                })}
              </CrossedPrice>
            )}
            <Text type="bodyLarge" color="text" fontWeight={700}>
              {formatCurrency({
                amount: composition?.dmcPrice,
                currency,
              })}
            </Text>
          </Stack>
          <Stack direction="row">
            <Text type="bodySmall" color="gray">
              {t('pricePerNight', {
                price: formatCurrency({
                  amount: composition.dmcPricePerDay,
                  currency,
                }),
              })}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" gutterSize={0.5}>
        <RoomTag
          value={t('nGuests', {
            count: travelersAmount,
          })}
          icon={FiUsers}
        />
        <RoomTag value={bedRooms} icon={IcHotel} />
      </Stack>
      {composition?.promotions?.length > 0 && (
        <Stack
          style={{
            flexWrap: 'wrap',
            gap: '0.5rem',
          }}
        >
          {composition?.promotions?.map((promotion, index) => (
            <HotelRoomPromotion
              key={index}
              name={promotion?.value}
              kind={promotion?.promotionKind}
            />
          ))}
        </Stack>
      )}

      {state === 'confirmed' && (
        <ul style={{ paddingTop: '1rem' }}>
          {travelers?.map((traveler, index) => (
            <UI.DetailSectionItem key={index}>
              <UI.UserIcon kind={traveler?.kind} color={theme.textLighter} />
              <UI.DetailsSectionItemInner>
                <UI.Text isName>
                  {traveler?.firstName} {traveler?.lastName}
                </UI.Text>
                <Stack direction="row" alignY="center">
                  <MdCalendarToday
                    style={{ color: theme.textLighter }}
                    size={18}
                    aria-hidden="true"
                  />
                  <time
                    dateTime={traveler?.birthdate}
                    style={{ marginLeft: theme.spacing(0.5) }}
                  >
                    {formatDate(traveler?.birthdate, 'dd / MM / yyyy')}
                  </time>
                </Stack>
                {traveler?.passportNumber && (
                  <Stack as="dl" direction="column" className="full">
                    <Stack>
                      <dt>{t('passportNumber', { ns: 'bookingForm' })} :</dt>
                      <dd>&nbsp;{traveler?.passportNumber}</dd>
                    </Stack>
                    <Stack>
                      <dt>{t('expirationDate', { ns: 'bookingForm' })} :</dt>
                      <dd>
                        &nbsp;
                        <time dateTime={traveler?.expirationDate}>
                          {formatDate(
                            traveler?.expirationDate,
                            'dd / MM / yyyy',
                          )}
                        </time>
                      </dd>
                    </Stack>
                  </Stack>
                )}
              </UI.DetailsSectionItemInner>
            </UI.DetailSectionItem>
          ))}
        </ul>
      )}
    </UI.DetailsSectionCard>
  );
};

const BookingRecap = ({ booking }) => {
  const theme = useTheme();
  const { t } = useTranslation('bookings');

  return (
    <UI.Wrapper>
      <Stack direction="column" gutterSize={1} style={{ flex: 2 }}>
        <CardTitle>{t('bookingDetails', { ns: 'bookings' })}</CardTitle>

        <SectionCard style={{ marginBottom: theme.spacing(1) }}>
          <Stack direction="column" gutterSize={1} size="full">
            <HotelDetailCard hotel={booking?.hotel} />
            <Stack
              size="full"
              alignX="space-between"
              alignY="center"
              gutterSize={2}
            >
              <ItemCard>
                <DateRange
                  startAt={booking?.startAt}
                  endAt={booking?.endAt}
                  size="full"
                />
              </ItemCard>

              {Boolean(booking?.startAt) && Boolean(booking?.endAt) && (
                <Stack gutterSize={0.25} alignY="center">
                  <IoMoonOutline size={18} />
                  <Legend style={{ whiteSpace: 'nowrap' }}>
                    {t('nNightsStay', {
                      count: booking?.numberOfNights,
                    })}
                  </Legend>
                </Stack>
              )}

              <ItemCard>
                <Stack gutterSize={0.25} alignY="center">
                  <IoPeopleOutline size={18} />
                  <span>
                    {t('nGuests', {
                      count: sum(
                        (booking?.roomCompositions || []).map(
                          ({ travelers }) => travelers?.length || 0,
                        ),
                      ),
                    })}
                  </span>
                </Stack>
                <Stack gutterSize={0.25} alignY="center">
                  <IcOrganization size={18} />
                  <span>
                    {t('nRooms', {
                      count: (booking?.roomCompositions || []).length,
                    })}
                  </span>
                </Stack>
              </ItemCard>
            </Stack>

            <Stack
              size="full"
              alignY="center"
              gutterSize={1}
              alignX="space-between"
            >
              <div>
                <Text fontWeight={700} type="bodyLarge">
                  {t('total')}
                </Text>
              </div>
              <Stack gutterSize={1} alignY="center">
                {booking?.dmcTotalPriceWithoutPromo !== booking?.totalPrice && (
                  <CrossedPrice type="bodyLarge">
                    {formatCurrency({
                      amount: booking?.dmcTotalPriceWithoutPromo,
                      currency: booking?.currency,
                    })}
                  </CrossedPrice>
                )}
                <Text color="orange" type="bodyLarge" fontWeight={700}>
                  {formatCurrency({
                    amount: booking?.totalPrice,
                    currency: booking?.currency,
                  })}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </SectionCard>

        {/* Rooms details DMC */}
        {booking?.roomCompositions?.map((composition, index) => {
          return (
            <DetailsCard
              key={index}
              state={booking?.state}
              composition={composition}
              index={index}
              currency={booking?.currency}
            />
          );
        })}
      </Stack>

      {/* Right column */}
      <Stack direction="column" style={{ flex: 1 }} gutterSize={1}>
        {/* Notes */}
        <CardTitle>{t('notes', { ns: 'bookingForm' })}</CardTitle>
        <SectionCard>
          {booking?.note ? (
            <NoteCard>
              <Text type="body" color="black">
                {booking?.note}
              </Text>
            </NoteCard>
          ) : (
            <Text
              as="div"
              type="bodySmall"
              color="gray"
              style={{ textAlign: 'center' }}
            >
              {t('emptyNotes', { ns: 'bookings' })}
            </Text>
          )}
        </SectionCard>

        {/* Bookee */}
        <CardTitle>{t('bookingReferee', { ns: 'bookingForm' })}</CardTitle>
        <SectionCard>
          <Stack direction={{ default: 'column' }} gutterSize={0.5}>
            <Stack size="full" direction="column" gutterSize={0.5}>
              <strong>
                {booking?.customerFirstname} {booking?.customerLastname}
              </strong>
              {booking?.customerCompany && (
                <Text>{booking?.customerCompany}</Text>
              )}
              <Text>{booking?.customerAddress}</Text>
              {booking?.customerAdditionalAddress && (
                <Text>{booking?.customerAdditionalAddress}</Text>
              )}
              <Text>
                {booking?.customerZipCode} {booking?.customerCity}{' '}
              </Text>
              {Boolean(booking?.customerAdditionalAddress) && (
                <Text color="textLight">{booking.customerAddressTwo}</Text>
              )}
            </Stack>

            <Stack size="full" direction="column" gutterSize={0.25}>
              <Stack alignY="center" gutterSize={0.5}>
                <Stack>
                  <MdMailOutline fill={theme.textLighter} size={18} />
                </Stack>
                <Text>{booking?.customerEmail}</Text>
              </Stack>
            </Stack>
            <Stack alignY="center" gutterSize={0.5}>
              <Stack>
                <MdPhone fill={theme.textLighter} size={18} />
              </Stack>
              <Text>{booking?.customerPhoneNumber}</Text>
            </Stack>
          </Stack>
        </SectionCard>

        {/* Tour operator */}
        <CardTitle>{t('tourOperator', { ns: 'bookingForm' })}</CardTitle>
        <SectionCard>
          <TourOperator
            operator={{
              operatorTourName: booking?.operatorTourName,
              networkName: booking?.networkName,
            }}
          >
            <Text
              type="bodySmall"
              color="gray"
              as="div"
              style={{ textAlign: 'center' }}
            >
              {t('emptyTourOperator', { ns: 'bookings' })}
            </Text>
          </TourOperator>
        </SectionCard>
      </Stack>
    </UI.Wrapper>
  );
};

export default BookingRecap;
