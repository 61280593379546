import { useField } from 'formik';
import { FormLabel, ErrorMessageWrapper, FormField } from 'ui/forms';
import Stepper from 'components/Stepper';

const StepperField = ({ label, name, ...props }) => {
  const [{ value }, meta, { setValue }] = useField(name);

  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  return (
    <FormField>
      {label && <FormLabel>{label}</FormLabel>}
      <Stepper value={value} onChange={setValue} {...props} />
      {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default StepperField;
