import styled from 'styled-components';
import { Text } from 'ui';
import { Stack } from '@tymate/margaret';
import { MdStar } from 'react-icons/md';
import { times } from 'lodash';

const Card = styled(Stack).attrs({
  direction: 'row',
  size: 'full',
  alignX: 'stretch',
  alignY: 'stretch',
})`
  border-radius: 4px;
  color: inherit;
  text-decoration: none;
`;

const CardContent = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
  alignX: 'stretch',
  gutterSize: 1,
})`
  flex: 1 1 65%;
  padding: ${({ theme }) => theme.spacing()};
  border: 1px solid ${({ theme }) => theme.separator};
  border-left: 0;
  border-radius: 0 4px 4px 0;
  background-color: #ffffff;

  ${({ variant }) =>
    variant === 'infoWindow' &&
    `
      border: 0;
      padding-left: 0;
      padding-right: 0;
    `}
`;

const Picture = styled.div`
  flex: 1 1 35%;
  padding-left: 30%;
  border-radius: 4px 0 0 4px;
  background: ${({ theme }) => theme.separator};
  background-size: cover;
  background-position: center center;

  ${({ variant }) =>
    variant === 'infoWindow' &&
    `
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    `}

  ${({ backgroundImage }) =>
    Boolean(backgroundImage) &&
    `
     background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.75)
      ), url(${backgroundImage})
    `};
`;

const CardTitle = styled(Text).attrs({ type: 'h2' })`
  padding-bottom: ${({ theme }) => theme.spacing(0)};
`;

const Rate = styled(Stack)`
  color: ${({ theme }) => theme.yellow};
  font-size: 24px;
`;

const HotelDetailCardContent = ({ hotel, variant }) => {
  return (
    <CardContent variant={variant}>
      <CardTitle>{hotel?.displayName}</CardTitle>
      <Stack direction="column" gutterSize={0.25}>
        <div>
          <Text color="textLight" type="body">
            {hotel?.address}
            {Boolean(hotel?.address) && Boolean(hotel?.cityName) && ', '}
            {hotel?.cityName}
          </Text>
        </div>
        <div>
          <Text color="textLight" type="body">
            {hotel?.regionName}
            {Boolean(hotel?.regionName) && Boolean(hotel?.countryName) && ', '}
            {hotel?.countryName}
          </Text>
        </div>
      </Stack>
      <Rate>
        {times(hotel?.stars ?? 0, index => (
          <MdStar key={index} />
        ))}
      </Rate>
    </CardContent>
  );
};

const HotelDetailCard = ({ hotel }) => (
  <Card>
    <Picture backgroundImage={encodeURI(hotel?.primaryAttachment?.imageUrl)} />
    <HotelDetailCardContent hotel={hotel} />
  </Card>
);

export default HotelDetailCard;
