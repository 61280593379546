import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Stack } from '@tymate/margaret';
import { Text, Tab } from 'ui';
import { getYesterday, formatDate } from 'utils';

// TODO get the total count of one day is not available from the back
const GET_BOOKINGS_TOTAL_COUNT = gql`
  query {
    adminBookings {
      totalCount
    }
  }
`;

const TabBookingsByDay = ({ path }) => {
  const { t } = useTranslation('bookings');
  const { data } = useQuery(GET_BOOKINGS_TOTAL_COUNT);
  const yesterday = getYesterday();

  return (
    <Tab to={path} variant="bordered" size="large" end>
      <Stack size="full" alignY="center">
        <Stack direction="column" size="full" gutterSize={0.5}>
          <Text type="body">{t('dayByDay')}</Text>
          <Stack alignY="center" gutterSize={0.5}>
            <Text type="h1Mega">{data?.adminBookings?.totalCount || 0}</Text>
            <MdKeyboardArrowRight size={20} />
            <Text type="body">{formatDate(yesterday, 'dd.MM.yyyy')}</Text>
          </Stack>
        </Stack>
        <MdKeyboardArrowRight size={36} />
      </Stack>
    </Tab>
  );
};

export default TabBookingsByDay;
